import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useNavigate} from "react-router-dom";

import Hackenbush from '../../resources/games/Hackenbush.jpg';
import TikTakToe from '../../resources/games/tic-tac-toe.png'
import Battleship from '../../resources/games/Battleship.png'


import './home-css.css';
import {useContext, useEffect} from "react";
import {GameModeContext} from "../Games/Hackenbush/Provider/GameModeProvider";


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const useGameMode = () => useContext(GameModeContext);
export default function Home() {
    const navigate = useNavigate();
    const appBarStyle = {
        backgroundColor: '#834333', // Change the background color to a suitable color
        borderBottom: '2px solid #834333', // Add a bottom border
        color: '#fff', // Text color
    };

    const {handleModeSelect} = useGameMode();

    useEffect(() => {
        handleModeSelect('')
    }, []);
    return (

        <div className="background">
            <AppBar position="relative" sx={appBarStyle}>
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        Paper And Pencil Games.
                    </Typography>
                </Toolbar>
            </AppBar>
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="md" className="custom-container">

                        <h1 className="multi-color-text">
                            Paper And Pencil Games.
                        </h1>
                        <h4 align="center" className="responsive-text">
                            Classic Paper and Pencil Games – Now Online!
                        </h4>
                    </Container>

                </Box>
                <Container sx={{py: 8}} maxWidth="md" className="custom-container">
                    {/* End hero unit */}
                    <Grid container spacing={4} justifyContent="center">

                        <Grid item key={1} xs={10} sm={6} md={4}>
                            <Card
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: 8, // Add rounded corners
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add a shadow
                                    backgroundColor: '#D2B48C',
                                    border: '5px solid #e0e0e0',
                                    '&:hover': {
                                        transform: 'scale(1.03)',
                                        cursor: 'pointer',
                                        animation: 'shine 1s infinite'
                                    },
                                }}
                                onClick={() => navigate("/hackenbush")}
                            >
                                <CardMedia
                                    component="div"
                                    sx={{
                                        paddingTop: '100%', // 16:9 aspect ratio
                                        backgroundSize: 'cover', // Make sure the image covers the entire area
                                        backgroundImage: `url(${Hackenbush})`, // Use your image source here
                                    }}
                                    image={Hackenbush}
                                />
                                <CardContent sx={{flexGrow: 1}}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Hackenbush
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item key={1} xs={10} sm={6} md={4}>
                            <Card
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: 8, // Add rounded corners
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add a shadow
                                    backgroundColor: '#D2B48C',
                                    border: '5px solid #e0e0e0',
                                    '&:hover': {
                                        transform: 'scale(1.03)',
                                        cursor: 'pointer',
                                        animation: 'shine 1s infinite'
                                    },
                                }}
                                onClick={() => navigate("/tiktaktoe")}
                            >
                                <CardMedia
                                    component="div"
                                    sx={{
                                        // 16:9
                                        pt: '100%',
                                        backgroundColor: 'white'
                                    }}
                                    image={TikTakToe}
                                />
                                <CardContent sx={{flexGrow: 1}}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Tic-Tak-Toe
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item key={1} xs={10} sm={6} md={4}>
                            <Card
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: 8, // Add rounded corners
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add a shadow
                                    backgroundColor: '#D2B48C',
                                    border: '5px solid #e0e0e0',
                                    '&:hover': {
                                        transform: 'scale(1.03)',
                                        cursor: 'pointer',
                                        animation: 'shine 1s infinite'
                                    },
                                }}
                                onClick={() => navigate("/battleship")}
                            >
                                <CardMedia
                                    component="div"
                                    sx={{
                                        // 16:9
                                        pt: '100%',
                                        backgroundColor: 'white'
                                    }}
                                    image={Battleship}
                                />
                                <CardContent sx={{flexGrow: 1}}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Battle Ship
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );

}