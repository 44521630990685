import React, {useContext, useEffect, useState} from "react";
import { Typography, Container, Grid, Card, CardContent, CardMedia } from "@mui/material";
import { figureEdges1, figureNodes1, figureEdges2, figureNodes2, figureEdges3, figureNodes3, figureEdges4, figureNodes4 } from "./figures-graph";
import figure1 from '../../../resources/figure1.png';
import figure2 from '../../../resources/figure2.png';
import figure3 from '../../../resources/figure3.png';
import figure4 from '../../../resources/figure4.png';
import PlayerSelectionDialog from './playerModeSelectionDialog';
import {GameModeContext} from "./Provider/GameModeProvider";

const hackenbushFigures = [
  { id: 1, name: "Figure 1", nodes: figureNodes1, edges: figureEdges1, image: figure1 },
  { id: 2, name: "Figure 2", nodes: figureNodes2, edges: figureEdges2, image: figure2 },
  { id: 3, name: "Figure 3", nodes: figureNodes3, edges: figureEdges3, image: figure3 },
  { id: 4, name: "Figure 4", nodes: figureNodes4, edges: figureEdges4, image: figure4 },
  // Add more figures as needed
]
const useGameMode = () => useContext(GameModeContext);
export default function FigureSelection({ onSelectFigure }) {
    const {selectedMode} = useGameMode()
  const [selectedFigure, setSelectedFigure] = useState(null);
  const [isDialogOpen, handleGameModeDialogVisibility] = useState(false);

  useEffect(()=>{
      if(selectedFigure !=null) {
          onSelectFigure(selectedFigure);
      }
  },[selectedMode])

  const handleFigureSelect = (figure) => {
      handleGameModeDialogVisibility(true);
      setSelectedFigure(figure);
  };

  return (
    <div style={{ padding: '32px 0' }}>
      <PlayerSelectionDialog open={isDialogOpen} onClose={() => handleGameModeDialogVisibility(false)} onStart = {() =>{}} />
      <Typography
        variant="h6"
        color="textPrimary"
        gutterBottom
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          padding: '16px',
          backgroundColor: '#E1C16E',
          fontSize: '24px',
          color: '#191970',
          marginTop: '16px',
          marginBottom: '32px', // Add space between title and container
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        Choose a Hackenbush Figure to Play
      </Typography>
      <Container>
        <Grid container justifyContent="center" spacing={2}>
          {hackenbushFigures.map((figure) => (
            <Grid item key={figure.id}>
              <Card
              onClick={() => handleFigureSelect(figure)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '16px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  backgroundColor: '#DAA06D',
                  border: selectedFigure?.id === figure.id ? '5px solid #2196F3' : '5px solid #f0f8ff',
                  transition: 'transform 0.2s',
                  padding: '16px',
                  marginBottom: '16px', // Add space between cards
                  '&:hover': {
                    transform: 'scale(1.03)',
                    cursor: 'pointer'
                  },
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 250, height: 200 }}
                  image={figure.image}
                />
                <CardContent>
                  <Typography variant="h6" color="textPrimary">
                    {figure.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
