import React from "react";
import { AppBar, Toolbar, Typography, Button, createTheme, ThemeProvider } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const theme = createTheme();

export default function NavBar({game}) {
    const navigate = useNavigate();


  const goToHome = () => {
    navigate('/');
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" style={{   backgroundColor: '#834333', // Change the background color to a suitable color
      borderBottom: '2px solid #834333', // Add a bottom border
      color: '#fff', // Text color
  }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
           {game.name}
          </Typography>
          <Button color="inherit" onClick={goToHome}>
            Home
          </Button>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
