import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { GameModeContext } from './Provider/GameModeProvider';
import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const useGameMode = () => useContext(GameModeContext);

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '300px',
    borderRadius: '10px',
    backgroundColor: '#f5f5f5',
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: '#E1C16E',
  color: '#fff',
  fontSize: '20px',
  fontWeight: 'bold',
  padding: '16px',
});

const StyledDialogContent = styled(DialogContent)({
  padding: '24px',
});

const StyledFormControlLabel = styled(FormControlLabel)({
  marginBottom: '16px',
});

const StyledButton = styled(Button)({
  marginTop: '16px',
});

// Receive open and onClose props from the parent component
function PlayerSelectionDialog({ open, onClose, onStart }) {
  const [selectedMode, setSelectedMode] = useState('');
  const { handleModeSelect } = useGameMode();

  const handleModeChange = (event) => {
    setSelectedMode(event.target.value);
  };

  const handleStartGame = (event) => {
    if (selectedMode) {
      handleModeSelect(selectedMode);
      onStart();
    } else {
      alert('Please select a game mode.');
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="player-selection-dialog-title"
    >
      <StyledDialogTitle id="player-selection-dialog-title">
        Select Game Mode
      </StyledDialogTitle>
      <StyledDialogContent>
        <RadioGroup
          value={selectedMode}
          onChange={handleModeChange}
          aria-label="game-mode-selection"
        >
          <StyledFormControlLabel
            value="MACHINE"
            control={<Radio />}
            label="Play With Computer"
          />
          <StyledFormControlLabel
            value="HUMAN"
            control={<Radio />}
            label="Play With Human"
          />
        </RadioGroup>
        <StyledButton
          variant="contained"
          style={{
            backgroundColor: '#DAA06D',}}
          onClick={handleStartGame}
        >
          Start Game
        </StyledButton>
      </StyledDialogContent>
    </StyledDialog>
  );
}

export default PlayerSelectionDialog;