import './App.css';
import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './components/home/home';
import Hackenbush from './components/Games/Hackenbush/hackenbush'
import TikTakToe from './components/Games/tik-tak-toe/tik-tak-toe';
import {GameModeProvider} from "./components/Games/Hackenbush/Provider/GameModeProvider";
import BattleShip from "./components/Games/BattleShip/BattleShip";

function App() {
  return (
      <GameModeProvider>
    <Router>
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route exact path="/hackenbush" element={<Hackenbush/>} />
      <Route exact path="/tiktaktoe" element={<TikTakToe/>} />
      <Route exact path="/battleship" element={<BattleShip/>}/>
    </Routes>
  </Router>
      </GameModeProvider>
  );
}

export default App;
